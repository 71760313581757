import React, { useState, useEffect } from 'react';
import './EditCustomerPage.css'; // Reuse AddCustomerPage styles
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import apiClient from '../../../apiClient';

const EditCustomersPage: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [type, setType] = useState('Klant');
  const [errorMessage, setErrorMessage] = useState(''); // State for error messages

  const { userId } = useParams<{ userId: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await apiClient.get(`/api/users/${userId}`);
        const userData = response.data.user;
        setName(userData.name);
        setEmail(userData.email);
        setType(userData.type);
      } catch (error) {
        console.error('Error fetching user:', error);
        setErrorMessage('Er is een fout opgetreden bij het ophalen van gebruikersgegevens.');
      }
    };
    fetchUser();
  }, [userId]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await apiClient.patch(`/api/users/${userId}`, {
        name,
        email,
        password,
        type,
      });
      console.log('User updated successfully');
      navigate('/admin/customers');
      // Clear any previous errors
      setErrorMessage('');
    } catch (error: any) {
      console.error('Error updating user:', error);
      if (error.response && error.response.status === 422) {
        // Extract and set validation error message
        const errors = error.response.data.errors;
        if (errors.email) {
          setErrorMessage(errors.email[0]); // Email-specific error
        } else {
          setErrorMessage('Er is een fout opgetreden. Probeer het opnieuw.');
        }
      } else {
        setErrorMessage('Er is een fout opgetreden. Probeer het opnieuw.');
      }
    }
  };

  return (
    <div>
      <div className="edit-customer-container">
        <div className="content">
          <h2>Klant bewerken</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Naam:</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Wachtwoord:</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="type">Type gebruiker:</label>
              <select
                id="type"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="Klant">Klant</option>
                <option value="Coach">Coach</option>
                <option value="Admin">Admin</option>
              </select>
            </div>
            <button type="submit">Bewerken</button>
            {errorMessage && <div className="error-message">{errorMessage}</div>} {/* Error message */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditCustomersPage;
