// src/pages/DashboardListPage/DashboardListPage.tsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import './DashboardsHomePage.css';
import { THEME_COLOR } from '../../colors';
import Dashboard from '../../types/Dashboard';
import User from '../../types/User';
import apiClient from '../../apiClient';
import DigiRedoGD from '../../images/digiRedo-logo-GD.png'
import DigiRedoPaard from '../../images/digiRedo-logo-Paard.png'
import DigiRedoRund from '../../images/digiRedo-logo-Rund.png'
import FarmAnimals from '../../images/farm-animals.jpg'

const DashboardsHomePage: React.FC = () => {
  const [dashboards, setDashboards] = useState<Dashboard[]>([]);
  const [coaches, setCoaches] = useState<User[]>([]);
  const { practiceId } = useParams<{ practiceId: string }>();
  const [allowedDashboardTypes, setAllowedDashboardTypes] = useState<string[]>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const fetchPracticeDashboards = async () => {
    const userJSON = localStorage.getItem('user');
    if (userJSON && practiceId) {
      const user: User = JSON.parse(userJSON);
      if (user.type == 'Admin') {
        try {
          const response = await apiClient.get(`/api/practices/${practiceId}`, {
            params: {
              userId: user.id,
            }});
          setDashboards(response.data.practice.dashboards);
        } catch (error) {
          console.error('Error fetching dashboards:', error);
        }
      } else {
        try {
          const response = await apiClient.get(`/api/practices/${practiceId}/user`, {
            params: {
              userId: user.id,
            }});
          setDashboards(response.data.practice.dashboards);
        } catch (error) {
          console.error('Error fetching dashboards:', error);
        }
      }
    }
  };

  const fetchAllowedDashboardTypes = async () => {
    const userJSON = localStorage.getItem('user');
    if (userJSON && practiceId) {
      const user: User = JSON.parse(userJSON);
      try {
        const response = await apiClient.get(`/api/users/${user.id}/retrieve-types`, {
          params: { practiceId },
        });
        setAllowedDashboardTypes(JSON.parse(response.data.allowedDashboardTypes));
      } catch (error) {
        console.error('Error fetching allowed dashboard types:', error);
      }
    }
  };

  const fetchCoaches = async () => {
    if (practiceId){
      try {
        const response = await apiClient.get(`/api/practices/${practiceId}/coaches`);
        setCoaches(response.data.coaches);
      } catch (error) {
        console.error('Error fetching allowed dashboard types:', error);
      }
    }
  }

  useEffect(() => {
    Promise.all([
      fetchPracticeDashboards(),
      fetchAllowedDashboardTypes(),
      fetchCoaches(),
    ]).finally(() => setLoading(false));
  }, [practiceId]);

  const handleDashboardClick = (dashboardId: number) => {
    navigate(`/dashboard/${dashboardId}`);
  };

  // const renderIcon = (type: string) => {
  //   switch (type) {
  //     case 'GD':
  //       return '/images/digiRedo-logo-GD.png';
  //     case 'Paard':
  //       return '/images/digiRedo-logo-Paard.png';
  //     case 'Rund':
  //       return '/images/digiRedo-logo-Rund.png';
  //     case 'LBH':
  //       return '/images/farm-animals.jpg';
  //     default:
  //       return null;
  //   }
  // };

  const renderIcon = (type: string) => {
    switch (type) {
      case 'GD':
        return DigiRedoGD;
      case 'Paard':
        return DigiRedoPaard;
      case 'Rund':
        return DigiRedoRund;
      case 'LBH':
        return FarmAnimals;
      default:
        return null;
    }
  };

  // const renderIcon = (type: string) => {
  //   const basePath = process.env.PUBLIC_URL || '';
  //   switch (type) {
  //     case 'GD':
  //       return `${basePath}/images/digiRedo-logo-GD.png`;
  //     case 'Paard':
  //       return `${basePath}/images/digiRedo-logo-Paard.png`;
  //     case 'Rund':
  //       return `${basePath}/images/digiRedo-logo-Rund.png`;
  //     case 'LBH':
  //       return `${basePath}/images/farm-animals.jpg`;
  //     default:
  //       return null;
  //   }
  // };

  const filteredDashboards = dashboards.filter(dashboard =>
    allowedDashboardTypes.includes(dashboard.type_dashboard)
  );

  if (loading) {
    return <div>
      <></>
    </div>; // Show a loading indicator while data is being fetched
  }

  return (
    <div className="content">
      <h2>Dashboards</h2>
      <div className="dashboard-list">
      {filteredDashboards.map(dashboard => {
        const backgroundImage = renderIcon(dashboard.type_dashboard);
        return (
          <button
            key={dashboard.id}
            className="dashboard-button"
            onClick={() => handleDashboardClick(dashboard.id)}
            style={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundColor: THEME_COLOR,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <div className="dashboard-overlay" />
            <div className="dashboard-text">
              {dashboard.type_dashboard}
            </div>
          </button>
        );
      })}
      </div>
      <h3>Coaches</h3>
      <div className="coaches-list">
        {coaches.map(coach => (
          <div key={coach.id} className="coach">
            <p><strong>Naam:</strong> {coach.name}</p>
            <p><strong>Email:</strong> {coach.email}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardsHomePage;
