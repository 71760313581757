import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import User from '../../types/User';
import './ChangePasswordPage.css';
import { THEME_COLOR } from '../../colors';
import apiClient from '../../apiClient';

interface ChangePasswordPageProps {
  refresh: () => Promise<void>;
}

const ChangePasswordPage: React.FC<ChangePasswordPageProps> = ({ refresh }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      alert('Passwords do not match');
      return;
    }

    console.log(localStorage.getItem('user'));

    const userJSON = localStorage.getItem('user');
    if (!userJSON) {
      alert('User not found. Please log in again.');
      return;
    }
    const user: User = JSON.parse(userJSON);

    if(user){
        try {
        const response = await apiClient.post(`/api/change-password/${user!.id}`, {
            current_password: currentPassword,
            new_password: newPassword,
            new_password_confirmation: confirmPassword,
        });

        alert('Wachtwoord veranderd!');

        user!.password_changed = true;

        localStorage.setItem('user', JSON.stringify(user));
        await refresh();
        navigate('/');  // Redirect to homepage or login page
        } catch (error) {
        console.error('Error changing password:', error);
        alert(error);
        }
    }
  };

  return (
    <div className="content">
      <h2>Wachtwoord veranderen</h2>
      <form className='form-input' onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="currentPassword">Huidig wachtwoord:</label>
          <input
            type="password"
            id="currentPassword"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="newPassword">Nieuw wachtwoord:</label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">Herhaling nieuw wachtwoord:</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <button style={{backgroundColor: THEME_COLOR}} type="submit">Wachtwoord veranderen</button>
      </form>
    </div>
  );
};

export default ChangePasswordPage;
