import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Pdf from '../../types/Pdf';
import './PdfPage.css';
import apiClient from '../../apiClient';

const PdfPage: React.FC = () => {
  const { pdfId } = useParams<{ pdfId: string }>();
  const [pdf, setPdf] = useState<Pdf | null>(null);

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await apiClient.get(`/api/pdfs/${pdfId}`);
        setPdf(response.data);
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    };

    fetchPdf();
  }, [pdfId]);

  if (!pdf) {
    return <div>Toegang geweigerd</div>;
  }

  return (
    <div className="view-pdf-page">
      <h2>{pdf.name}</h2>
      <div className="pdf-container">
        {/* Display PDF in iframe */}
        <div>
        {pdf.url && (
            <iframe
            src={pdf.url}
            width="100%"
            height="1000px"
            title="PDF Viewer"
            ></iframe>
        )}
        </div>
      </div>
    </div>
  );
};

export default PdfPage;