import React, { useState, useEffect } from 'react';
import './EditDashboardPage.css'; // Reuse AddDashboardPage styles
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import apiClient from '../../../apiClient';

const EditDashboardPage: React.FC = () => {
  const [name, setName] = useState('');
  const [gripDashboard, setGripDashboard] = useState('geen');
  const [typeDashboard, setTypeDashboard] = useState('GD');
  const [boostkey, setBoostkey] = useState('');
  const [urlDashboard, setUrlDashboard] = useState('');
  const { dashboardId } = useParams<{ dashboardId: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDashboard = async () => {
      try {
        const response = await apiClient.get(`/api/dashboards/${dashboardId}`);
        const dashboardData = response.data.dashboard;
        setName(dashboardData.name);
        setGripDashboard(dashboardData.grip_dashboard);
        setTypeDashboard(dashboardData.type_dashboard);
        setBoostkey(dashboardData.boostkey);
        setUrlDashboard(dashboardData.url_dashboard);
      } catch (error) {
        console.error('Error fetching dashboard:', error);
      }
    };
    fetchDashboard();
  }, [dashboardId]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await apiClient.patch(`/api/dashboards/${dashboardId}`, {
        name,
        grip_dashboard: gripDashboard,
        type_dashboard: typeDashboard,
        boostkey: gripDashboard === 'boost' ? boostkey : null,
        url_dashboard: gripDashboard === 'digiredo' ? urlDashboard : null,
      });
      console.log('Dashboard updated successfully');
      navigate('/admin/dashboards');
    } catch (error) {
      console.error('Error updating dashboard:', error);
    }
  };

  return (
    <div>
      <div className='add-dashboard-container'>
        <div className='content'>
          <h2>Dashboard Bewerken</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Naam:</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="gripDashboard">Type Dashboard:</label>
              <select
                id="typeDashboard"
                value={typeDashboard}
                onChange={(e) => setTypeDashboard(e.target.value)}
              >
                <option value="GD">GD</option>
                {/* <option value="LBH">LBH</option> */}
                <option value="Paard">Paard</option>
                <option value="Rund">Rund</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="gripDashboard">Grip Dashboard:</label>
              <select
                id="gripDashboard"
                value={gripDashboard}
                onChange={(e) => setGripDashboard(e.target.value)}
              >
                <option value="geen">Geen</option>
                <option value="boost">Boost</option>
                <option value="digiredo">Digiredo</option>
              </select>
            </div>
            {gripDashboard === 'boost' && (
              <div className="form-group">
                <label htmlFor="boostkey">Boostkey:</label>
                <input
                  type="text"
                  id="boostkey"
                  value={boostkey}
                  onChange={(e) => setBoostkey(e.target.value)}
                />
              </div>
            )}
            {gripDashboard === 'digiredo' && (
              <div className="form-group">
                <label htmlFor="urlDashboard">URL Dashboard:</label>
                <input
                  type="text"
                  id="urlDashboard"
                  value={urlDashboard}
                  onChange={(e) => setUrlDashboard(e.target.value)}
                />
              </div>
            )}
            <button type="submit">Bewerken</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditDashboardPage;