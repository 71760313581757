// Check if the theme color is already set in session storage
let storedThemeColor = sessionStorage.getItem('themeColor');

// If the theme color is not set, generate a new random color and store it in session storage
if (!storedThemeColor) {
    storedThemeColor = getRandomColor();
    sessionStorage.setItem('themeColor', storedThemeColor);
  }
  
  // Export the theme color
  export const THEME_COLOR = storedThemeColor;
  
  // Function to generate a random color
  function getRandomColor() {
    const colors = ['#0088CE', '#69B342', '#F58220', '#DB4699']; // Define your color options here (geen geel)
    return colors[Math.floor(Math.random() * colors.length)];
  }