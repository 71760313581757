import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import User from '../../../types/User';
import Practice from '../../../types/Practice';
import './CustomerDetailPage.css';
import { THEME_COLOR } from '../../../colors';
import apiClient from '../../../apiClient';

const CustomerDetailPage: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();

  const [user, setUser] = useState<User | null>(null);
  const [practices, setPractices] = useState<Practice[]>([]);
  const [userPractices, setUserPractices] = useState<{ practice: Practice, departments: string[] }[]>([]);
  const [availablePractices, setAvailablePractices] = useState<Practice[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const response = await apiClient.get(`/api/users/${userId}`);
        const userData = response.data.user;
        setUser(userData);
        setUserPractices(userData.practices.map((practice: Practice) => ({
          practice,
          departments: practice.pivot ? JSON.parse(practice.pivot.departments) : []
        })));
      } catch (error) {
        console.error('Error fetching customer:', error);
      }
    };

    const fetchPractices = async () => {
      try {
        const response = await apiClient.get('/api/practices');
        setPractices(response.data.practices || []);
      } catch (error) {
        console.error('Error fetching practices:', error);
      }
    };

    Promise.all([fetchCustomer(), fetchPractices()]).finally(() => setLoading(false));
  }, [userId]);

  useEffect(() => {
    const available = practices.filter(practice =>
      !userPractices.some(userPractice => userPractice.practice.id === practice.id)
    );
    setAvailablePractices(available);
  }, [practices, userPractices]);

  const handleAddPractice = async (practiceId: number) => {
    try {
      await apiClient.post(`/api/users/${userId}/attach-practice`, { practiceId });
      const addedPractice = practices.find(practice => practice.id === practiceId);
      if (addedPractice) {
        setUserPractices([...userPractices, { practice: addedPractice, departments: [] }]);
        setAvailablePractices(availablePractices.filter(practice => practice.id !== practiceId));
      }
    } catch (error) {
      console.error('Error adding practice:', error);
    }
  };

  const handleRemovePractice = async (practiceId: number) => {
    try {
      await apiClient.post(`/api/users/${userId}/detach-practice`, { practiceId });
      const removedPractice = userPractices.find(userPractice => userPractice.practice.id === practiceId);
      if (removedPractice) {
        setUserPractices(userPractices.filter(userPractice => userPractice.practice.id !== practiceId));
        setAvailablePractices([...availablePractices, removedPractice.practice]);
      }
    } catch (error) {
      console.error('Error removing practice:', error);
    }
  };

  const handleDepartmentChange = (practiceId: number, department: string, checked: boolean) => {
    setUserPractices(userPractices.map(userPractice => {
      if (userPractice.practice.id === practiceId) {
        const currentDepartments = userPractice.departments;
        const updatedDepartments = checked
          ? [...currentDepartments, department]
          : currentDepartments.filter(dep => dep !== department);

        apiClient.post(`/api/users/${userId}/update-practice-departments`, {
          practice_id: practiceId,
          departments: updatedDepartments
        });

        return { ...userPractice, departments: updatedDepartments };
      }
      return userPractice;
    }));
  };

  const departments = ['GD', 'Paard', 'Rund'];

  if (loading) {
    return <div>
      <></>
    </div>; // Show a loading indicator while data is being fetched
  }

  return (
    <div className="customer-detail-container">
      <div className="customer-detail-card">
        <h2 className="customer-detail-title">Gebruiker Details</h2>
        <div className="customer-detail-info">
          <p><strong>Naam:</strong> {user?.name}</p>
          <p><strong>Email:</strong> {user?.email}</p>
          <p><strong>Type:</strong> {user?.type}</p>
          <p><strong>Actief:</strong> {user?.is_active ? 'Ja' : 'Nee'}</p>
        </div>
        {!(user?.type == 'Admin') && <>
        <h3 className="practices-title">{userPractices.length > 0 ? 'Praktijk(en) van de Gebruiker' : ''}</h3>
        <ul className="practices-list">
          {userPractices.map(userPractice => (
            <li key={userPractice.practice.id}>
              <span className="practice-name">{userPractice.practice.name}</span>
              {user?.type == 'Klant' && <>
              <div className="departments">
                {departments.map(department => (
                  <label key={department}>
                    <input
                      type="checkbox"
                      checked={userPractice.departments.includes(department)}
                      onChange={(e) =>
                        handleDepartmentChange(userPractice.practice.id, department, e.target.checked)
                      }
                    />
                    {department}
                  </label>
                ))}
              </div>
              </>}
              <button style={{ textDecoration: "none", backgroundColor: THEME_COLOR }} onClick={() => handleRemovePractice(userPractice.practice.id)}>Verwijderen</button>
            </li>
          ))}
        </ul>
        {(userPractices.length == 0 || user?.type == 'Coach') && <>
          <h3 className="practices-title">Beschikbare Praktijken</h3>
          <ul className="practices-list">
            {availablePractices.map(practice => (
              <li key={practice.id}>
                <span className="practice-name">{practice.name}</span>
                <button style={{ textDecoration: "none", backgroundColor: THEME_COLOR }} onClick={() => handleAddPractice(practice.id)}>Toevoegen</button>
              </li>
            ))}
          </ul></>}
        </>}
      </div>
    </div>
  );
};

export default CustomerDetailPage;
