import React, { useState } from 'react';
import './AddCustomerPage.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import apiClient from '../../../apiClient';

const AddCustomerPage: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [type, setType] = useState('Klant');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // State for error messages
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await apiClient.post('/api/users', {
        name,
        email,
        type,
        password,
      });
      console.log('User created:', response.data.user);
      navigate('/admin/customers');
      // Reset the form fields
      setName('');
      setEmail('');
      setType('Klant');
      setPassword('');
      setErrorMessage(''); // Clear any previous errors
    } catch (error: any) {
      console.error('Error creating user:', error);
      if (error.response && error.response.status === 422) {
        // Extract and set validation error message
        const errors = error.response.data.errors;
        if (errors.email) {
          setErrorMessage(errors.email[0]); // Email-specific error
        } else {
          setErrorMessage('Er is een fout opgetreden. Probeer het opnieuw.');
        }
      } else {
        setErrorMessage('Er is een fout opgetreden. Probeer het opnieuw.');
      }
    }
  };

  return (
    <div>
      <div className="add-customer-container">
        <div className="content">
          <h2>Gebruiker toevoegen</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Naam:</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Wachtwoord:</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="type">Type gebruiker:</label>
              <select
                id="type"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="Klant">Klant</option>
                <option value="Coach">Coach</option>
                <option value="Admin">Admin</option>
              </select>
            </div>
            <button type="submit">Toevoegen</button>
            {errorMessage && <div className="error-message">{errorMessage}</div>} {/* Error message */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddCustomerPage;
