import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Report from '../../types/Report';
import './ReportsPage.css';
import apiClient from '../../apiClient';

const ReportsPage: React.FC = () => {
  const [reports, setReports] = useState<Report[]>([]);
  const { practiceId } = useParams<{ practiceId: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchReports();
  }, [practiceId]);

  const fetchReports = async () => {
    if (practiceId) {
      try {
        const response = await apiClient.get(`/api/practices/${practiceId}/reports`);
        response.data.sort((a: Report, b: Report) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
        setReports(response.data);
      } catch (error) {
        console.error('Error fetching reports:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="content">
      <h2>Verslagen</h2>
      <p>Hier kunt u de verslagen bekijken en downloaden.</p> {/* Descriptive text */}
      <div className="reports-list">
        {reports.map(report => (
          <div key={report.id} className="report-item" onClick={() => navigate(`/pdf/${report.pdf!.id}`)}>
            <h3>{report.name}</h3>
            <p>Aangemaakt op: {new Date(report.created_at).toLocaleDateString()}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportsPage;
