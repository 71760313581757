import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './LoginPage.css';
import { THEME_COLOR } from '../../colors';
import apiClient from '../../apiClient';

interface LoginProps {
  onLoginSuccess: (data: any) => void;
}

const LoginPage: React.FC<LoginProps> = ({ onLoginSuccess }) => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await apiClient.post('/api/login', { email, password });
      console.log('Login successful:', response.data);
      localStorage.setItem('isAdmin', response.data.user.is_admin);
      onLoginSuccess(response.data.user);
    } catch (error) {
      console.error('Login failed:', error);
      setError('Ongeldige naam of wachtwoord');
    }
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleSubmit}>
        <h1>Inloggen</h1>
        <p>Gebruik de gegevens die je van ons hebt ontvangen via de mail om in te loggen.</p>
        <div className="input-group">
          <input
            type="text"
            placeholder="E-mailadres"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="input-group">
          <input
            type="password"
            placeholder="Wachtwoord"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="btn-login" style={{backgroundColor: THEME_COLOR}}>
          Inloggen
        </button>
        {error && <div style={{color: 'red', paddingTop: '5px'}}>{error}</div>}
      </form>
    </div>
  );
};

export default LoginPage;