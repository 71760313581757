import React, { useState } from 'react';
import './AddPracticePage.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import apiClient from '../../../apiClient';

const AddPracticePage: React.FC = () => {
  // For practice
  const [practiceName, setPracticeName] = useState('');
  const [description, setDescription] = useState('');
  const [gripId, setGripId] = useState(1);

  // For user
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [type, setType] = useState('Klant');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const practiceResponse = await apiClient.post('/api/practices', {
        name: practiceName,
        description,
        grip_id: gripId
      });
      console.log('Practice created:', practiceResponse.data.practice);
      // Optionally, you can reset the form fields after successful submission
      setPracticeName('');
      setDescription('');
      setGripId(1);

      const userResponse = await apiClient.post('/api/users', {
        name: userName,
        email,
        type,
        password,
        practiceId: practiceResponse.data.practice.id,
      });
      console.log('User created:', userResponse.data.user);
      navigate('/admin/practices');
      // Optionally, you can reset the form fields after successful submission
      setUserName('');
      setEmail('');
      setType('Klant');
      setPassword('');
    } catch (error) {
      console.error('Error creating practice:', error);
      // Handle error, display message to user, etc.
    }
  };


  return (
    <div>
      <div className='add-practice-container'>
        <div className='content'>
          <h2>Praktijk Toevoegen</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Naam:</label>
              <input type="text" id="practiceName" value={practiceName} onChange={(e) => setPracticeName(e.target.value)} />
            </div>
            <div className="form-group">
              <label htmlFor="description">Beschrijving:</label>
              <textarea id="description" value={description} onChange={(e) => setDescription(e.target.value)} />
            </div>
            <div className="form-group">
              <label htmlFor="gripId">Grip:</label>
              <select
                id="gripId"
                value={gripId}
                onChange={(e) => setGripId(parseInt(e.target.value, 10))}
              >
                <option value={1}>Grip 1</option>
                <option value={2}>Grip 2</option>
                <option value={3}>Grip 3</option>
              </select>
            </div>


            <div style={{marginTop: '20px'}}>
              <h2>Gebruiker toevoegen</h2>
              
              <div className="form-group">
                <label htmlFor="name">Naam:</label>
                <input type="text" id="userName" value={userName} onChange={(e) => setUserName(e.target.value)} />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className="form-group">
                <label htmlFor="password">Wachtwoord:</label>
                <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>
              <div className="form-group">
                <label htmlFor="type">Type gebruiker</label>
                <select id="type" value={type} onChange={(e) => setType(e.target.value)}>
                  <option value="Klant">Klant</option>
                  <option value="Coach">Coach</option>
                  <option value="Admin">Admin</option>
                </select>
              </div>
            </div>

            <button type="submit">Toevoegen</button>
          </form>

          

        </div>
      </div>
    </div>
  );
};

export default AddPracticePage;
