import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Practice from '../../../types/Practice';
import './PracticeDetailPage.css';
import { THEME_COLOR } from '../../../colors';
import Dashboard from '../../../types/Dashboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import User from '../../../types/User';
import { format } from 'date-fns';
import Pdf from '../../../types/Pdf';
import Report from '../../../types/Report';
import apiClient from '../../../apiClient';

const PracticeDetailPage: React.FC = () => {
  const { practiceId } = useParams<{ practiceId: string }>();
  const navigate = useNavigate();

  const [practice, setPractice] = useState<Practice | null>(null);
  const [selectedDashboards, setSelectedDashboards] = useState<Dashboard[]>([]);
  const [practiceUsers, setPracticeUsers] = useState<User[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [message, setMessage] = useState<string>('');

  const [uploadedPdfs, setUploadedPdfs] = useState<Pdf[]>([]);

  const [dashboardToDelete, setDashboardToDelete] = useState<Dashboard | null>(null);
  const [pdfToDelete, setPdfToDelete] = useState<Pdf | null>(null);

  const [reports, setReports] = useState<Report[]>([]);
  const [reportToDelete, setReportToDelete] = useState<Report | null>(null);


  const fetchData = async () => {
    try {
      const [practiceResponse] = await Promise.all([
        apiClient.get(`/api/practices/${practiceId}`),
      ]);

      const practiceData: Practice = practiceResponse.data.practice;
      const selectedDashboards: Dashboard[] = practiceData.dashboards;
      const practiceUsers: User[] = practiceData.users;

      setPractice(practiceData);
      setSelectedDashboards(selectedDashboards);
      setPracticeUsers(practiceUsers);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const fetchUploadedPdfs = async () => {
    if (practiceId) {
      try {
        const response = await apiClient.get(`/api/practices/${practiceId}/uploaded-pdfs`);
        setUploadedPdfs(response.data);
      } catch (error) {
        console.error('Error fetching PDFs:', error);
      }
    }
  };

  const handleUpload = async () => {
    if (selectedFile && practiceId) {
      const formData = new FormData();
      formData.append('pdf', selectedFile);
      formData.append('type', 'upload');

      try {
        await apiClient.post(`/api/practices/${practiceId}/upload-pdf`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setMessage('File uploaded successfully');
        fetchUploadedPdfs();
      } catch (error) {
        setMessage('File upload failed');
      }
    }
  };

  const handleRowClick = (userId: number) => {
    navigate(`/admin/customer-details/${userId}`);
  };

  const handleAddDashboardClick = () => {
    navigate('/admin/add-dashboard', { state: { practice } });
  };

  const handleDeleteDashboardClick = (dashboard: Dashboard) => {
    setDashboardToDelete(dashboard);
  };

  const handleRemoveUser = async (userId: number) => {
    try {
      await apiClient.post(`/api/users/${userId}/detach-practice`, { practiceId });
      const removedUser = practiceUsers.find(practiceUser => practiceUser.id === userId);
      if (removedUser) {
        setPracticeUsers(practiceUsers.filter(practiceUser => practiceUser.id !== userId));
      }
    } catch (error) {
      console.error('Error removing practice:', error);
    }
  };

  const confirmDelete = async () => {
    if (dashboardToDelete) {
      try {
        await apiClient.delete(`/api/dashboards/${dashboardToDelete.id}`);
        setSelectedDashboards(selectedDashboards.filter(d => d.id !== dashboardToDelete.id));
      } catch (error) {
        console.error('Error deleting dashboard:', error);
      } finally {
        setDashboardToDelete(null);
      }
    }
  };

  const handleSoftDeletePdf = (pdf: Pdf) => {
    setPdfToDelete(pdf); // Show confirmation dialog with the selected PDF
  };

  const confirmDeletePdf = async () => {
    if (pdfToDelete) {
      try {
        await apiClient.delete(`/api/practices/pdf/${pdfToDelete.id}`);
        setMessage('PDF deleted successfully');
        setPdfToDelete(null);
        fetchUploadedPdfs(); // Refresh the list after deletion
      } catch (error) {
        setMessage('Failed to delete PDF');
        console.error('Error deleting PDF:', error);
      }
    }
    
  };

  const fetchReports = async () => {
    if (practiceId) {
      try {
        const response = await apiClient.get(`/api/practices/${practiceId}/reports`);
        setReports(response.data);
      } catch (error) {
        console.error('Error fetching reports:', error);
      }
    }
  };

  const handleSoftDeleteReport = (report: Report) => {
    setReportToDelete(report); // Show confirmation dialog with the selected report
  };

  const confirmDeleteReport = async () => {
      if (reportToDelete) {
          try {
              await apiClient.delete(`/api/reports/${reportToDelete.id}`);
              setMessage('Report deleted successfully');
              fetchReports(); // Refresh the report list after deletion
          } catch (error) {
              setMessage('Failed to delete report');
              console.error('Error deleting report:', error);
          } finally {
              setReportToDelete(null);
          }
      }
  };
  
  
  useEffect(() => {
    fetchData();
    fetchUploadedPdfs();
    fetchReports();
  }, [practiceId]);


  return (
    <div className="practice-detail-container">
      <div className="practice-detail-card">
        <h2 className="practice-detail-title">Praktijk Details</h2>
        <div className="practice-detail-info">
          <p><strong>Naam:</strong> {practice?.name}</p>
          <p><strong>Beschrijving:</strong> {practice?.description}</p>
          <p><strong>Grip</strong> {practice?.grip_id}</p>
        </div>

        <h3 className="dashboards-title">Gebruikers</h3>

        <table className="user-table">
          <thead>
            <tr>
              <th style={{ cursor: 'pointer' }}>Naam</th>
              <th style={{ cursor: 'pointer' }}>Email</th>
              <th style={{ cursor: 'pointer' }}>Datum registratie</th>
              <th style={{ cursor: 'pointer' }}>Type</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {practiceUsers.map((user) => (
              <tr
                key={user.id}
                onClick={() => handleRowClick(user.id)}
                style={{ cursor: 'pointer' }}
              >
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{format(new Date(user.created_at), 'dd-MM-yyyy')}</td>
                <td>{user.type}</td>
                <td>
                  <a onClick={(e) => e.stopPropagation()} href={`/admin/edit-customer/${user.id}`} style={{ textDecoration: "none", color: THEME_COLOR }}>Bewerken</a>
                </td>
                <td>
                  <FontAwesomeIcon 
                    icon={faTrash} 
                    style={{ color: THEME_COLOR, marginLeft: '10px', cursor: 'pointer' }} 
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveUser(user.id);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <h3 className="dashboards-title">Dashboards</h3>
        <table className="dashboard-table">
          <thead>
            <tr>
              <th style={{ cursor: 'pointer' }}>Naam</th>
              <th style={{ cursor: 'pointer' }}>Grip Dashboard</th>
              <th style={{ cursor: 'pointer' }}>Type</th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {selectedDashboards.map((dashboard) => (
              <tr key={dashboard.id}>
                <td>{dashboard.name}</td>
                <td>{dashboard.grip_dashboard}</td>
                <td>{dashboard.type_dashboard}</td>
                <td><a href={`/admin/edit-dashboard/${dashboard.id}`} style={{ textDecoration: "none", color: THEME_COLOR }}>Bewerken</a></td>
                <td><a href={`/dashboard/${dashboard.id}`} style={{ textDecoration: "none", color: THEME_COLOR }}>Bekijk dashboard</a></td>
                <td>
                  <FontAwesomeIcon 
                    icon={faTrash} 
                    style={{ color: THEME_COLOR, marginLeft: '10px', cursor: 'pointer' }} 
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteDashboardClick(dashboard);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {dashboardToDelete && (
          <div className="confirmation-dialog">
            <p>Wil je dit dashboard verwijderen?</p>
            <button onClick={confirmDelete}>Ja</button>
            <button onClick={() => setDashboardToDelete(null)}>Nee</button>
          </div>
        )}

        <button 
          style={{ marginBottom: '20px', backgroundColor: THEME_COLOR }} 
          onClick={handleAddDashboardClick} // Add the onClick handler
        >
          Dashboard toevoegen
        </button>

        <h2>PDF Uploaden</h2>
        <input type="file" accept="application/pdf" onChange={handleFileChange} />
        <button style={{backgroundColor: THEME_COLOR}} onClick={handleUpload}>Upload</button>
        {message && <p>{message}</p>}
        <h3>PDFs geupload</h3>
        <div className="pdf-list">
          {uploadedPdfs.map(pdf => (
            <div key={pdf.id} className="pdf-item">
              {/* <a className="download-pdf" onClick={() => handleDownloadPdf(pdf.id, pdf.name)}> */}
              <a className="download-pdf" onClick={() => navigate(`/pdf/${pdf.id}`)}>
                {pdf.name}
              </a>
              <FontAwesomeIcon 
                icon={faTrash} 
                style={{ color: THEME_COLOR, marginLeft: '10px', cursor: 'pointer' }} 
                onClick={() => handleSoftDeletePdf(pdf)} 
              />
            </div>
          ))}
        </div>

        {pdfToDelete && (
          <div className="confirmation-dialog">
            <p>Weet je zeker dat je dit PDF wilt verwijderen?</p>
            <button onClick={confirmDeletePdf}>Ja</button>
            <button onClick={() => setPdfToDelete(null)}>Nee</button>
          </div>
        )}

        <h3 className="reports-title">Verslagen</h3>
        <table className="report-table">
          <thead>
            <tr>
              <th>Naam</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {reports.map((report) => (
              <tr key={report.id}>
                {report.pdf && ( <td style={{ cursor: 'pointer' }} onClick={() => navigate(`/pdf/${report.pdf!.id}`)}>{report.name}</td> )}
                {!report.pdf && ( <td>{report.name}</td> )}
                <td><a href={`/practice/edit-report/${report.id}`} style={{ textDecoration: "none", color: THEME_COLOR }}>Bewerken</a></td>
                <td>
                  <FontAwesomeIcon 
                    icon={faTrash} 
                    style={{ color: THEME_COLOR, marginLeft: '0px', cursor: 'pointer' }} 
                    onClick={() => handleSoftDeleteReport(report)} 
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {reportToDelete && (
          <div className="confirmation-dialog">
            <p>Weet je zeker dat je het verslag wilt verwijderen?</p>
            <button onClick={confirmDeleteReport}>Ja</button>
            <button onClick={() => setReportToDelete(null)}>Nee</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PracticeDetailPage;
