import React, { useEffect, useState, useRef } from 'react';
import './DashboardPage.css';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import User from '../../types/User';
import apiClient from '../../apiClient';
import * as powerbi from 'powerbi-client';
import { THEME_COLOR } from '../../colors';

const DashboardPage: React.FC = () => {
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [url, setUrl] = useState('');
  const reportContainerRef = useRef<HTMLDivElement>(null);
  const { dashboardId } = useParams<{ dashboardId: string }>();
  const [embedConfig, setEmbedConfig] = useState<powerbi.IEmbedConfiguration | null>(null);
  const [flag, setFlag] = useState(0);
  const navigate = useNavigate();



  useEffect(() => {
    const fetchDashboard = async () => {
      const userJSON = localStorage.getItem('user');
      if (userJSON && dashboardId) {
        const user = JSON.parse(userJSON);
        try {
          const response = await apiClient.get(`/api/dashboards/${dashboardId}`, {
            params: { userId: user.id }
          });

          const { embedUrl, embedToken, reportId } = response.data;
          setName(response.data.dashboard.name);
          setType(response.data.dashboard.type_dashboard);
          setUrl(response.data.dashboard.url_dashboard);
          console.log(response.data);
          setEmbedConfig({
            type: 'report',
            id: reportId,
            embedUrl: embedUrl,
            accessToken: embedToken,
            tokenType: powerbi.models.TokenType.Embed,
            settings: {
              panes: {
                filters: { visible: false },
                pageNavigation: { visible: true },
              }
            }
          });

        } catch (error) {
          console.error('Error fetching dashboard:', error);
        }
      }
    };

    fetchDashboard();
  }, [dashboardId]);

  useEffect(() => {
    if (embedConfig && reportContainerRef.current) {
      const powerbiService = new powerbi.service.Service(
        powerbi.factories.hpmFactory,
        powerbi.factories.wpmpFactory,
        powerbi.factories.routerFactory
      );
      
      // Flag to prevent double loading
      if (flag == 0){
        powerbiService.embed(reportContainerRef.current, embedConfig);
        setFlag(1);
      }
      
    }
  }, [embedConfig]);


  return (
    <div className='content'>
      <h2>{name}</h2>
      <a className="return-home" href="/" style={{ cursor: 'pointer', textDecoration: 'none', color: THEME_COLOR, marginBottom: '10px' }}>
        <p>← Terug naar hoofdpagina</p>
      </a>
      {type == 'Paard' || type == 'Rund' ? (
        <iframe
          width="100%"
          height="700"
          frameBorder="0"
          allowFullScreen={true}
          src={url}
        ></iframe>
      ) : (
        <div ref={reportContainerRef} style={{ height: '700px', width: '100%' }}></div>
      )}
      {/* {urlDashboard ? (
        <iframe
          width="100%"
          height="700"
          frameBorder="0"
          allowFullScreen={true}
          src={urlDashboard}
        ></iframe>
      ) : (
        <p></p>
      )} */}
      {/* <iframe width="100%" height="700" frameBorder="0" allowFullScreen={true} src="https://app.powerbi.com/view?r=eyJrIjoiOGRiNjJkOGYtNjQ4OC00YzU3LTkyZDEtYTE0ZTIzYWE4ZDU0IiwidCI6IjgzOTViODBlLWYyM2YtNDg5Yi1hNjc2LWVhMGVjNjA3MDM1ZSIsImMiOjh9&pageName=ReportSectionb6de98f8eeba6044853c"></iframe> */}
    </div>
  );
};

export default DashboardPage;