import React, { useState } from 'react';
import './AddDashboardPage.css';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import apiClient from '../../../apiClient';

const AddDashboardPage: React.FC = () => {
  const [name, setName] = useState('');
  const [gripDashboard, setGripDashboard] = useState('geen');
  const [typeDashboard, setTypeDashboard] = useState('GD');
  const [urlDashboard, setUrlDashboard] = useState('');
  const [boostkey, setBoostkey] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const practice = location.state?.practice;
  const practiceId = practice?.id;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await apiClient.post('/api/dashboards', {
        name,
        grip_dashboard: gripDashboard,
        type_dashboard: typeDashboard,
        url_dashboard: gripDashboard === 'digiredo' ? urlDashboard : null,
        boostkey: gripDashboard === 'boost' ? boostkey : null,
        practiceId,
      });
      console.log('Dashboard created:', response.data.dashboard);
      navigate('/admin/dashboards');

      // Optionally, you can reset the form fields after successful submission
      setName('');
      setGripDashboard('geen');
      setTypeDashboard('GD');
      setUrlDashboard('');
      setBoostkey('');
    } catch (error) {
      console.error('Error creating dashboard:', error);
      // Handle error, display message to user, etc.
    }
  };

  return (
    <div>
      <div className='add-dashboard-container'>
        <div className='content'>
          <h2>{practice?.name}</h2>
          <h3>Dashboard Toevoegen</h3>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Naam:</label>
              <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="form-group">
              <label htmlFor="typeDashboard">Type Dashboard:</label>
              <select
                id="typeDashboard"
                value={typeDashboard}
                onChange={(e) => setTypeDashboard(e.target.value)}
              >
                <option value="GD">GD</option>
                {/* <option value="LBH">LBH</option> */}
                <option value="Paard">Paard</option>
                <option value="Rund">Rund</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="gripDashboard">Grip Dashboard:</label>
              <select id="gripDashboard" value={gripDashboard} onChange={(e) => setGripDashboard(e.target.value)}>
                <option value="geen">Geen</option>
                <option value="boost">Boost</option>
                <option value="digiredo">Digiredo</option>
              </select>
            </div>
            {gripDashboard === 'boost' && (
              <div className="form-group">
                <label htmlFor="boostkey">Boost Key:</label>
                <input type="text" id="boostkey" value={boostkey} onChange={(e) => setBoostkey(e.target.value)} />
              </div>
            )}
            {gripDashboard === 'digiredo' && (
              <div className="form-group">
                <label htmlFor="urlDashboard">URL Dashboard:</label>
                <input type="text" id="urlDashboard" value={urlDashboard} onChange={(e) => setUrlDashboard(e.target.value)} />
              </div>
            )}
            <button type="submit">Toevoegen</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddDashboardPage;