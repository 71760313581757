import React from 'react';
import './Sidebar.css';
import { THEME_COLOR } from '../../colors';
import User from '../../types/User';
import Practice from '../../types/Practice';
import { Link } from 'react-router-dom';

interface SideBarProps {
  onLogout: () => void;
  isLoggedIn: boolean;
  user: User | null;
  practices: Practice[] | null;
}

const Sidebar: React.FC<SideBarProps> = ({ isLoggedIn, user, practices, onLogout }) => {
  return (
    <div className="sidebar-container">
      {isLoggedIn && user?.type == 'Admin' ? (
      <div className="sidebar">
        <h3>PRAKTIJKEN</h3>
        <div style={{display: "flex", flexDirection: "column", paddingLeft: "10px", paddingBottom: "20px"}}>
          <a style={{textDecoration: "none", color: THEME_COLOR}} href="/admin/practices">Overzicht</a>
          <a style={{textDecoration: "none", color: THEME_COLOR}} href="/admin/add-practice">Toevoegen</a>
          <a style={{textDecoration: "none", color: THEME_COLOR}} href="/practice/add-report">Verslag aanmaken</a>
        </div>
        <h3>GEBRUIKERS</h3>
        <div style={{display: "flex", flexDirection: "column", paddingLeft: "10px", paddingBottom: "20px"}}>
          <a style={{textDecoration: "none", color: THEME_COLOR}} href="/admin/customers">Overzicht</a>
          <a style={{textDecoration: "none", color: THEME_COLOR}} href="/admin/add-customer">Toevoegen</a>
        </div>
        <h3>DASHBOARDS</h3>
        <div style={{display: "flex", flexDirection: "column", paddingLeft: "10px", paddingBottom: "20px"}}>
          <a style={{textDecoration: "none", color: THEME_COLOR}} href="/admin/dashboards">Overzicht</a>
        </div>
        <div style={{display: "flex", flexDirection: "column", paddingLeft: "10px", paddingBottom: "20px"}}>
          <a style={{textDecoration: "none", color: THEME_COLOR}} href="/user-password-change">Wachtwoord veranderen</a>
        </div>
        <div
          style={{
            textAlign: 'center',
            padding: '5px',
            marginTop: '50px',
            color: 'white',
            backgroundColor: THEME_COLOR,
            cursor: 'pointer',
          }}
          onClick={onLogout}
        >
          Uitloggen
        </div>
      </div>
      ) : null}
      {isLoggedIn && user?.type == 'Klant' ? (
      <div className="sidebar">
        <h3>OVERZICHT</h3>
        <div style={{display: "flex", flexDirection: "column", paddingLeft: "10px", paddingBottom: "20px"}}>
          <a style={{textDecoration: "none", color: THEME_COLOR}} href="/">Dashboards</a>
          <a style={{textDecoration: "none", color: THEME_COLOR}} href={practices && practices.length > 0 ? `/practice/${practices[0].id}/reports` : '/'}>Verslagen</a>
          <a style={{textDecoration: "none", color: THEME_COLOR}} href={practices && practices.length > 0 ? `/practice/${practices[0].id}/uploadedPDFs` : '/'}>Bestanden</a>
          {/* <a style={{textDecoration: "none", color: THEME_COLOR}} href="/user-password-change">Wachtwoord veranderen</a> */}
        </div>
        <div style={{display: "flex", flexDirection: "column", paddingLeft: "10px", paddingBottom: "20px"}}>
          <a style={{textDecoration: "none", color: THEME_COLOR}} href="/user-password-change">Wachtwoord veranderen</a>
        </div>
        <div
          style={{
            textAlign: 'center',
            padding: '5px',
            marginTop: '50px',
            color: 'white',
            backgroundColor: THEME_COLOR,
            cursor: 'pointer',
          }}
          onClick={onLogout}
        >
          Uitloggen
        </div>
      </div>
      ): null}
      {isLoggedIn && user?.type == 'Coach' ? (
      <div className="sidebar">
        <h3>PRAKTIJKEN</h3>
        <div style={{display: "flex", flexDirection: "column", paddingLeft: "10px", paddingBottom: "20px"}}>
          <a style={{textDecoration: "none", color: THEME_COLOR}} href="/">Overzicht</a>
          <a style={{textDecoration: "none", color: THEME_COLOR}} href="/practice/add-report">Verslag aanmaken</a>
        </div>
        <div style={{display: "flex", flexDirection: "column", paddingLeft: "10px", paddingBottom: "20px"}}>
          <a style={{textDecoration: "none", color: THEME_COLOR}} href="/user-password-change">Wachtwoord veranderen</a>
        </div>
        <div
          style={{
            textAlign: 'center',
            padding: '5px',
            marginTop: '50px',
            color: 'white',
            backgroundColor: THEME_COLOR,
            cursor: 'pointer',
          }}
          onClick={onLogout}
        >
          Uitloggen
        </div>
      </div>
      ): null}
    </div>
  );
};

export default Sidebar;