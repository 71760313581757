// src/pages/PracticeListPage/PracticeListPage.tsx

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './CoachHomePage.css';
import { THEME_COLOR } from '../../../colors';
import Practice from '../../../types/Practice';

interface CoachHomePageProps {
  practices: Practice[];
}

type SortOrder = 'asc' | 'desc' | 'none';

const CoachHomePage: React.FC<CoachHomePageProps> = ({ practices }) => {
  const navigate = useNavigate();

  const handlePracticeClick = (practice: Practice) => {
    navigate(`/practice/${practice.id}`);
  };

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [sortConfig, setSortConfig] = useState<{ key: keyof Practice | null, order: SortOrder }>({ key: null, order: 'none' });

  const handleRowClick = (practiceId: number) => {
    navigate(`/coach/practice-details/${practiceId}`);
  };

  const handleSort = (key: keyof Practice) => {
    let order: SortOrder = 'asc';
    if (sortConfig.key === key && sortConfig.order === 'asc') {
      order = 'desc';
    } else if (sortConfig.key === key && sortConfig.order === 'desc') {
      order = 'none';
    }
    setSortConfig({ key, order });
  };

  const sortedFilteredPractices = () => {
    let filteredPractices = practices.filter(practice =>
      practice.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (sortConfig.key && sortConfig.order !== 'none') {
      filteredPractices.sort((a, b) => {
        const aValue = a[sortConfig.key!];
        const bValue = b[sortConfig.key!];

        if (aValue == null || bValue == null) {
          return 0;
        }

        if (aValue < bValue) {
          return sortConfig.order === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.order === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    return filteredPractices;
  };

  const getSortIcon = (key: keyof Practice) => {
    if (sortConfig.key === key) {
      if (sortConfig.order === 'asc') {
        return ' ▲';
      } else if (sortConfig.order === 'desc') {
        return ' ▼';
      }
    }
    return '';
  };

  return (
    <div className="content">
        <h2>Praktijken</h2>
        <input
        type="text"
        placeholder="Zoeken..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
        />
        <table className="practice-table">
        <thead>
            <tr>
            <th onClick={() => handleSort('name')} style={{ cursor: 'pointer' }}>Naam{getSortIcon('name')}</th>
            <th onClick={() => handleSort('grip_id')} style={{ cursor: 'pointer' }}>Grip{getSortIcon('grip_id')}</th>
            <th></th>
            <th></th>
            </tr>
        </thead>
        <tbody>
            {sortedFilteredPractices().map((practice) => (
            <tr key={practice.id}
                onClick={() => handleRowClick(practice.id)}
                style={{ cursor: 'pointer' }}
            >
                <td>{practice.name}</td>
                <td>{practice.grip_id}</td>
                <td></td>
                <td></td>
            </tr>
            ))}
        </tbody>
        </table>
    </div>
  );
};

export default CoachHomePage;
