import React, { useState, useEffect } from 'react';
import './EditPracticePage.css'; // Reuse AddPracticePage styles
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import apiClient from '../../../apiClient';

const EditPracticePage: React.FC = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState<string|undefined>('');
  const [gripId, setGripId] = useState(0);

  const { practiceId } = useParams<{ practiceId: string }>();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPractice = async () => {
      try {
        const response = await apiClient.get(`/api/practices/${practiceId}`);
        const practiceData = response.data.practice;
        setName(practiceData.name);
        setIfDefined(setDescription, practiceData.description);
        setGripId(practiceData.grip_id);
      } catch (error) {
        console.error('Error fetching practice:', error);
      }
    };
    fetchPractice();
  }, [practiceId]);

  const setIfDefined = (setStateFunction: any, value: any) => {
    if (value !== undefined) {
      setStateFunction(value);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await apiClient.patch(`/api/practices/${practiceId}`, {
        name,
        description,
        grip_id: gripId,
      });
      console.log('Practice updated successfully');
      navigate('/admin/practices');
    } catch (error) {
      console.error('Error updating practice:', error);
    }
  };

  return (
    <div>
      <div className='add-practice-container'>
        <div className='content'>
          <h2>Praktijk Bewerken</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Naam:</label>
              <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} />
            </div>
            <div className="form-group">
              <label htmlFor="description">Beschrijving:</label>
              <textarea id="description" value={description} onChange={(e) => setDescription(e.target.value)} />
            </div>
            <div className="form-group">
              <label htmlFor="gripId">Grip:</label>
              <input type="number" id="gripId" value={gripId} onChange={(e) => setGripId(parseInt(e.target.value, 10))} />
            </div>
            <button type="submit">Bewerken</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditPracticePage;
