import React, { useEffect, useState } from 'react';
import './CustomersPage.css';
import axios from 'axios';
import { format } from 'date-fns';
import { THEME_COLOR } from '../../../colors';
import User from '../../../types/User';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';
import apiClient from '../../../apiClient';

type SortOrder = 'asc' | 'desc' | 'none';

const CustomersPage: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [deletedUsers, setDeletedUsers] = useState<User[]>([]);
  const [showDeleted, setShowDeleted] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [sortConfig, setSortConfig] = useState<{ key: keyof User | null, order: SortOrder }>({ key: null, order: 'none' });
  const [userToDelete, setUserToDelete] = useState<User | null>(null);
  const [userToReinstate, setUserToReinstate] = useState<User | null>(null);
  const navigate = useNavigate();

  const handleRowClick = (userId: number) => {
    navigate(`/admin/customer-details/${userId}`);
  };

  const handleSort = (key: keyof User) => {
    let order: SortOrder = 'asc';
    if (sortConfig.key === key && sortConfig.order === 'asc') {
      order = 'desc';
    } else if (sortConfig.key === key && sortConfig.order === 'desc') {
      order = 'none';
    }
    setSortConfig({ key, order });
  };

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const response = await apiClient.get('/api/users');
        setUsers(response.data.users);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchAllUsers();
  }, []);

  const fetchDeletedUsers = async () => {
    try {
      const response = await apiClient.get('/api/users/deleted');
      setDeletedUsers(response.data.users);
    } catch (error) {
      console.error('Error fetching deleted users:', error);
    }
  };

  const toggleShowDeleted = () => {
    if (!showDeleted) {
      fetchDeletedUsers();
    }
    setShowDeleted(!showDeleted);
  };

  const sortedFilteredUsers = () => {
    let filteredUsers = users.filter(user =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (sortConfig.key && sortConfig.order !== 'none') {
      filteredUsers.sort((a, b) => {
        const aValue = a[sortConfig.key!];
        const bValue = b[sortConfig.key!];

        if (aValue == null || bValue == null) {
          return 0;
        }

        if (aValue < bValue) {
          return sortConfig.order === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.order === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    return filteredUsers;
  };

  const sortedFilteredDeletedUsers = () => {
    let filteredUsers = deletedUsers?.filter(user =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (sortConfig.key && sortConfig.order !== 'none') {
      filteredUsers.sort((a, b) => {
        const aValue = a[sortConfig.key!];
        const bValue = b[sortConfig.key!];

        if (aValue == null || bValue == null) {
          return 0;
        }

        if (aValue < bValue) {
          return sortConfig.order === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.order === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    return filteredUsers;
  };

  const getSortIcon = (key: keyof User) => {
    if (sortConfig.key === key) {
      if (sortConfig.order === 'asc') {
        return ' ▲';
      } else if (sortConfig.order === 'desc') {
        return ' ▼';
      }
    }
    return '';
  };

  const handleDeleteClick = (user: User) => {
    setUserToDelete(user);
  };

  const confirmDelete = async () => {
    if (userToDelete) {
      try {
        await apiClient.delete(`/api/users/${userToDelete.id}`);
        setUsers(users.filter(u => u.id !== userToDelete.id));
      } catch (error) {
        console.error('Error deleting user:', error);
      } finally {
        setUserToDelete(null);
      }
    }
  };

  const handleReinstateClick = (user: User) => {
    setUserToReinstate(user);
  };

  const confirmReinstate = async () => {
    if (userToReinstate) {
      try {
        await apiClient.post(`/api/users/${userToReinstate.id}/reinstate`);
        setDeletedUsers(deletedUsers.filter(u => u.id !== userToReinstate.id));
        setUsers([...users, userToReinstate]);
      } catch (error) {
        console.error('Error reinstating user:', error);
      } finally {
        setUserToReinstate(null);
      }
    }
  };

  return (
    <div className='content'>
      <div className='top'>
        <div>
          <h2>Gebruikers</h2>
        </div>
        <div>
          <input
            type="text"
            placeholder="Zoeken..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
        </div>
      </div>
      <table className="user-table">
        <thead>
          <tr>
            <th onClick={() => handleSort('name')} style={{ cursor: 'pointer' }}>Naam{getSortIcon('name')}</th>
            <th onClick={() => handleSort('email')} style={{ cursor: 'pointer' }}>Email{getSortIcon('email')}</th>
            <th onClick={() => handleSort('created_at')} style={{ cursor: 'pointer' }}>Datum registratie{getSortIcon('created_at')}</th>
            <th onClick={() => handleSort('type')} style={{ cursor: 'pointer' }}>Type{getSortIcon('type')}</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sortedFilteredUsers().map((user) => (
            <tr
              key={user.id}
              onClick={() => handleRowClick(user.id)}
              style={{ cursor: 'pointer' }}
            >
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{format(new Date(user.created_at), 'dd-MM-yyyy')}</td>
              <td>{user.type}</td>
              <td>
                <a onClick={(e) => e.stopPropagation()} href={`/admin/edit-customer/${user.id}`} style={{ textDecoration: "none", color: THEME_COLOR }}>Bewerken</a>
              </td>
              <td>
                <FontAwesomeIcon 
                  icon={faTrash} 
                  style={{ color: THEME_COLOR, marginLeft: '10px', cursor: 'pointer' }} 
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteClick(user);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {userToDelete && (
        <div className="confirmation-dialog">
          <p>Wil je deze gebruiker verwijderen?</p>
          <button onClick={confirmDelete}>Ja</button>
          <button onClick={() => setUserToDelete(null)}>Nee</button>
        </div>
      )}

      <button style={{ marginTop: '20px', marginBottom: '20px', backgroundColor: THEME_COLOR }} onClick={toggleShowDeleted}>
        {showDeleted ? 'Verberg verwijderde gebruikers' : 'Toon verwijderde gebruikers'}
      </button>

      {showDeleted && (
        <table className="user-table">
          <thead>
            <tr>
              <th onClick={() => handleSort('name')} style={{ cursor: 'pointer' }}>Naam{getSortIcon('name')}</th>
              <th onClick={() => handleSort('email')} style={{ cursor: 'pointer' }}>Email{getSortIcon('email')}</th>
              <th onClick={() => handleSort('created_at')} style={{ cursor: 'pointer' }}>Datum registratie{getSortIcon('created_at')}</th>
              <th onClick={() => handleSort('type')} style={{ cursor: 'pointer' }}>Type{getSortIcon('type')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {sortedFilteredDeletedUsers().map((user) => (
              <tr key={user.id}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{format(new Date(user.created_at), 'dd-MM-yyyy')}</td>
                <td>{user.type}</td>
                <td>
                  <FontAwesomeIcon 
                    icon={faUndo} 
                    style={{ color: THEME_COLOR, marginLeft: '10px', cursor: 'pointer' }} 
                    onClick={(e) => {
                      e.stopPropagation();
                      handleReinstateClick(user);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {userToReinstate && (
        <div className="confirmation-dialog">
          <p>Wil je deze gebruiker weer actief stellen?</p>
          <button onClick={confirmReinstate}>Ja</button>
          <button onClick={() => setUserToReinstate(null)}>Nee</button>
        </div>
      )}
    </div>
  );
};

export default CustomersPage;
