import React, { useState, useEffect } from 'react';
import './AddReportPage.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Practice from '../../types/Practice';
import apiClient from '../../apiClient';

const AddReportPage: React.FC = () => {
    const [practices, setPractices] = useState<Practice[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedPractice, setSelectedPractice] = useState<Practice | null>(null);
    const [reportName, setReportName] = useState('');
    const [klanten, setKlanten] = useState('');
    const [verrichtingen, setVerrichtingen] = useState('');
    const [team, setTeam] = useState('');
    const [financien, setFinancien] = useState('');
    const [overig, setOverig] = useState('');
    const navigate = useNavigate();
    const practiceLimit = 3; // Limit the amount of practices shown
  
    useEffect(() => {
      const fetchPractices = async () => {
        try {
          const response = await apiClient.get('/api/practices');
          setPractices(response.data.practices);
        } catch (error) {
          console.error('Error fetching practices:', error);
        }
      };
  
      fetchPractices();
    }, []);
  
    const filteredPractices = practices
      .filter(practice =>
        practice.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .slice(0, practiceLimit); // Limit the amount of practices shown
  
    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      if (!selectedPractice) {
        alert("Selecteer eerst een praktijk!");
        return;
      }
      try {
        const response = await apiClient.post('/api/reports', {
          practice_id: selectedPractice.id,
          name: reportName,
          klanten,
          verrichtingen,
          team,
          financien,
          overig,
        });
        console.log('Report created:', response.data);
        navigate(`/pdf/${response.data.pdf.id}`);
        setReportName('');
        setSelectedPractice(null);
        setKlanten('');
        setVerrichtingen('');
        setTeam('');
        setFinancien('');
        setOverig('');
      } catch (error) {
        console.error('Error creating report:', error);
      }
    };
  
    return (
      <div className="add-report-container">
        <div className="content">
          <h2>Verslag aanmaken</h2>
  
          {/* Practice Selection */}
          <div className="form-group">
            <label htmlFor="practice-search">Selecteer een praktijk:</label>
            <input
              type="text"
              id="practice-search"
              placeholder="Zoek praktijk..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
            <ul className="practice-dropdown">
              {filteredPractices.map(practice => (
                <li
                  key={practice.id}
                  onClick={() => {
                    setSelectedPractice(practice);
                    setSearchTerm('');
                  }}
                  className="practice-dropdown-item"
                >
                  {practice.name}
                </li>
              ))}
            </ul>
            {selectedPractice && (
              <div className="selected-practice">
                <strong>Geselecteerde praktijk:</strong> {selectedPractice.name}
              </div>
            )}
          </div>
  
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Verslagnaam</label>
              <input type="text" id="reportName" value={reportName} onChange={(e) => setReportName(e.target.value)} />
            </div>
            <div className="form-group">
                <label htmlFor="klanten">Klanten:</label>
                <textarea
                id="klanten"
                value={klanten}
                onChange={(e) => setKlanten(e.target.value)}
                rows={5}
                className="large-textarea"
                />
            </div>
            <div className="form-group">
                <label htmlFor="verrichtingen">Verrichtingen:</label>
                <textarea
                id="verrichtingen"
                value={verrichtingen}
                onChange={(e) => setVerrichtingen(e.target.value)}
                rows={5}
                className="large-textarea"
                />
            </div>
            <div className="form-group">
                <label htmlFor="team">Team:</label>
                <textarea
                id="team"
                value={team}
                onChange={(e) => setTeam(e.target.value)}
                rows={5}
                className="large-textarea"
                />
            </div>
            <div className="form-group">
                <label htmlFor="financien">Financiën:</label>
                <textarea
                id="financien"
                value={financien}
                onChange={(e) => setFinancien(e.target.value)}
                rows={5}
                className="large-textarea"
                />
            </div>
            <div className="form-group">
                <label htmlFor="overig">Overig:</label>
                <textarea
                id="overig"
                value={overig}
                onChange={(e) => setOverig(e.target.value)}
                rows={5}
                className="large-textarea"
                />
            </div>
            <button type="submit" className="submit-button">
                Toevoegen
            </button>
            </form>
        </div>
        </div>
    );
};

export default AddReportPage;
