import React, { useEffect, useState } from 'react';
import './PracticesPage.css';
import axios from 'axios';
import { THEME_COLOR } from '../../../colors';
import Practice from '../../../types/Practice';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';
import apiClient from '../../../apiClient';

type SortOrder = 'asc' | 'desc' | 'none';

const PracticesPage: React.FC = () => {
  const [practices, setPractices] = useState<Practice[]>([]);
  const [deletedPractices, setDeletedPractices] = useState<Practice[]>([]);
  const [showDeleted, setShowDeleted] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [sortConfig, setSortConfig] = useState<{ key: keyof Practice | null, order: SortOrder }>({ key: null, order: 'none' });
  const [practiceToDelete, setPracticeToDelete] = useState<Practice | null>(null);
  const [practiceToReinstate, setPracticeToReinstate] = useState<Practice | null>(null);
  const navigate = useNavigate();

  const handleRowClick = (practiceId: number) => {
    navigate(`/admin/practice-details/${practiceId}`);
  };

  const handleSort = (key: keyof Practice) => {
    let order: SortOrder = 'asc';
    if (sortConfig.key === key && sortConfig.order === 'asc') {
      order = 'desc';
    } else if (sortConfig.key === key && sortConfig.order === 'desc') {
      order = 'none';
    }
    setSortConfig({ key, order });
  };

  useEffect(() => {
    const fetchAllPractices = async () => {
      try {
        const response = await apiClient.get('/api/practices');
        setPractices(response.data.practices);
      } catch (error) {
        console.error('Error fetching practices:', error);
      }
    };

    fetchAllPractices();
  }, []);

  const fetchDeletedPractices = async () => {
    try {
      const response = await apiClient.get('/api/practices/deleted');
      setDeletedPractices(response.data.practices);
    } catch (error) {
      console.error('Error fetching deleted practices:', error);
    }
  };

  const toggleShowDeleted = () => {
    if (!showDeleted) {
      fetchDeletedPractices();
    }
    setShowDeleted(!showDeleted);
  };

  const sortedFilteredPractices = () => {
    let filteredPractices = practices.filter(practice =>
      practice.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (sortConfig.key && sortConfig.order !== 'none') {
      filteredPractices.sort((a, b) => {
        const aValue = a[sortConfig.key!];
        const bValue = b[sortConfig.key!];

        if (aValue == null || bValue == null) {
          return 0;
        }

        if (aValue < bValue) {
          return sortConfig.order === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.order === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    return filteredPractices;
  };

  const sortedFilteredDeletedPractices = () => {
    let filteredPractices = deletedPractices?.filter(practice =>
      practice.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (sortConfig.key && sortConfig.order !== 'none') {
      filteredPractices.sort((a, b) => {
        const aValue = a[sortConfig.key!];
        const bValue = b[sortConfig.key!];

        if (aValue == null || bValue == null) {
          return 0;
        }

        if (aValue < bValue) {
          return sortConfig.order === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.order === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    return filteredPractices;
  };

  const getSortIcon = (key: keyof Practice) => {
    if (sortConfig.key === key) {
      if (sortConfig.order === 'asc') {
        return ' ▲';
      } else if (sortConfig.order === 'desc') {
        return ' ▼';
      }
    }
    return '';
  };

  const handleDeleteClick = (practice: Practice) => {
    setPracticeToDelete(practice);
  };

  const confirmDelete = async () => {
    if (practiceToDelete) {
      try {
        await apiClient.delete(`/api/practices/${practiceToDelete.id}`);
        setPractices(practices.filter(p => p.id !== practiceToDelete.id));
      } catch (error) {
        console.error('Error deleting practice:', error);
      } finally {
        setPracticeToDelete(null);
      }
    }
  };

  const handleReinstateClick = (practice: Practice) => {
    setPracticeToReinstate(practice);
  };

  const confirmReinstate = async () => {
    if (practiceToReinstate) {
      try {
        await apiClient.post(`/api/practices/${practiceToReinstate.id}/reinstate`);
        setDeletedPractices(deletedPractices.filter(p => p.id !== practiceToReinstate.id));
        setPractices([...practices, practiceToReinstate]);
      } catch (error) {
        console.error('Error reinstating practice:', error);
      } finally {
        setPracticeToReinstate(null);
      }
    }
  };

  return (
    <div className='content'>
      <h2>Praktijken</h2>
      <input
        type="text"
        placeholder="Zoeken..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />
      <table className="practice-table">
        <thead>
          <tr>
            <th onClick={() => handleSort('name')} style={{ cursor: 'pointer' }}>Naam{getSortIcon('name')}</th>
            <th onClick={() => handleSort('grip_id')} style={{ cursor: 'pointer' }}>Grip{getSortIcon('grip_id')}</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sortedFilteredPractices().map((practice) => (
            <tr key={practice.id}
                onClick={() => handleRowClick(practice.id)}
                style={{ cursor: 'pointer' }}
            >
              <td>{practice.name}</td>
              <td>{practice.grip_id}</td>
              <td>
                <a onClick={(e) => e.stopPropagation()} href={`/admin/edit-practice/${practice.id}`} style={{ textDecoration: "none", color: THEME_COLOR }}>Bewerken</a>
              </td>
              <td>
                <FontAwesomeIcon 
                  icon={faTrash} 
                  style={{ color: THEME_COLOR, marginLeft: '10px', cursor: 'pointer' }} 
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteClick(practice);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {practiceToDelete && (
        <div className="confirmation-dialog">
          <p>Wil je deze praktijk verwijderen?</p>
          <button onClick={confirmDelete}>Ja</button>
          <button onClick={() => setPracticeToDelete(null)}>Nee</button>
        </div>
      )}

      <button style={{marginTop: '20px', marginBottom: '20px', backgroundColor: THEME_COLOR}} onClick={toggleShowDeleted}>
        {showDeleted ? 'Verberg verwijderde praktijken' : 'Toon verwijderde praktijken'}
      </button>

      {showDeleted && (
        <table className="practice-table">
          <thead>
            <tr>
              <th onClick={() => handleSort('name')} style={{ cursor: 'pointer' }}>Naam{getSortIcon('name')}</th>
              <th onClick={() => handleSort('grip_id')} style={{ cursor: 'pointer' }}>Grip{getSortIcon('grip_id')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {sortedFilteredDeletedPractices()?.map((practice) => (
              <tr key={practice.id}
              >
                <td>{practice.name}</td>
                <td>{practice.grip_id}</td>
                <td>
                  <FontAwesomeIcon 
                    icon={faUndo} 
                    style={{ color: THEME_COLOR, marginLeft: '10px', cursor: 'pointer' }} 
                    onClick={(e) => {
                      e.stopPropagation();
                      handleReinstateClick(practice);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {practiceToReinstate && (
        <div className="confirmation-dialog">
          <p>Wil je deze praktijk weer actief stellen?</p>
          <button onClick={confirmReinstate}>Ja</button>
          <button onClick={() => setPracticeToReinstate(null)}>Nee</button>
        </div>
      )}
    </div>
  );
};

export default PracticesPage;
