import React, { useEffect, useState } from 'react';
import HomePage from './pages/HomePage/HomePage';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import { Route, Routes, useNavigate } from 'react-router-dom';
import CustomersPage from './pages/admin/CustomersPage/CustomersPage';
import AddCustomerPage from './pages/admin/AddCustomerPage/AddCustomerPage';
import LoginPage from './pages/LoginPage/LoginPage';
import User from './types/User';
import EditCustomersPage from './pages/admin/EditCustomerPage/EditCustomerPage';
import CustomerDetailPage from './pages/admin/CustomerDetailPage/CustomerDetailPage';
import DashboardsPage from './pages/admin/DashboardsPage/DashboardsPage';
import AddDashboardPage from './pages/admin/AddDashboardPage/AddDashboardPage';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import PracticesPage from './pages/admin/PracticesPage/PracticesPage';
import AddPracticePage from './pages/admin/AddPracticePage/AddPracticePage';
import EditPracticePage from './pages/admin/EditPracticePage/EditPracticePage';
import PracticeDetailPage from './pages/admin/PracticeDetailPage/PracticeDetailPage';
import EditDashboardPage from './pages/admin/EditDashboardPage/EditDashboardPage';
import PracticeListPage from './pages/PracticeListPage/PracticeListPage';
import DashboardsHomePage from './pages/DashboardsHomePage/DashboardsHomePage';
import Practice from './types/Practice';
import axios from 'axios';
import ChangePasswordPage from './pages/ChangePasswordPage/ChangePasswordPage';
import AddReportPage from './pages/AddReportPage/AddReportPage';
import PdfPage from './pages/PdfPage/PdfPage';
import EditReportPage from './pages/EditReportPage/EditReportPage';
import CoachHomePage from './pages/coach/CoachHomePage/CoachHomePage';
import CoachPracticeDetailPage from './pages/coach/CoachPracticeDetailPage/CoachPracticeDetailPage';
import ReportsPage from './pages/ReportsPage/ReportsPage';
import UploadedPDFsPage from './pages/UploadedPDFsPage/UploadedPDFsPage';
import apiClient from './apiClient';

const App: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [practices, setPractices] = useState<Practice[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const loggedInStatus = localStorage.getItem('isLoggedIn');
    if (loggedInStatus === 'true') {
      setIsLoggedIn(true);
    } else {
      // Clear login state if not logged in
      localStorage.removeItem('isLoggedIn');
      setIsLoggedIn(false);
    }

    const isAdminStatus = localStorage.getItem('isAdmin');
    if (isAdminStatus == 'true') {
      setIsAdmin(true);
    } else {
      // Clear login state if not logged in
      localStorage.setItem('isAdmin', 'false');
      setIsAdmin(false);
    }

    // Set the user from local storage
    // const userJSON = localStorage.getItem('user');
    // if (userJSON) {
    //   setUser(JSON.parse(userJSON));
    // }

    const userJSON = localStorage.getItem('user');
    if (userJSON) {
      const user: User = JSON.parse(userJSON);
      setUser(user);
      if (user.password_changed && window.location.pathname === '/change-password') {
        navigate('/'); // Redirect to homepage after password change
      }
    }
  }, []);

  const fetchUser = async () => {
    const userJSON = localStorage.getItem('user');
    if (userJSON) {
      const user: User = JSON.parse(userJSON);
      setUser(user);
      if (user.password_changed && window.location.pathname === '/change-password') {
        navigate('/'); // Redirect to homepage after password change
      }
    }
  }

  const fetchUserPractices = async () => {
    if (user) {
      try {
        const currentPath = window.location.pathname;
        const response = await apiClient.get(`/api/users/${user.id}`);
        setPractices(response.data.user.practices);
        // Below is the ugliest code ever
        if (response.data.user.practices.length === 1 && !currentPath.includes('user-password-change') && !currentPath.includes(`/reports`) && !currentPath.includes(`/uploadedPDFs`) && response.data.user.type == 'Klant') {
          navigate(`/practice/${response.data.user.practices[0].id}`);
        }
        if (!user.password_changed && user.type == 'Klant') {
          // Redirect them to the change password page
          navigate('/change-password');
        }
      } catch (error) {
        console.error('Error fetching practices:', error);
      }
    }
  };

  useEffect(() => {
    fetchUserPractices();
  }, [user]);
  

  const handleLoginSuccess = (user: User) => {
    if (user.type == 'Admin') {
      localStorage.setItem('isAdmin', 'true');
      setIsAdmin(true);
    } else {
      localStorage.setItem('isAdmin', 'false');
      setIsAdmin(false);
    }

    localStorage.setItem('user', JSON.stringify(user));
    setUser(user);

    localStorage.setItem('isLoggedIn', 'true');
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    setIsLoggedIn(false);
    setUser(null);
    navigate(`/`);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100vh' }}>
      <Header onLogout={handleLogout} isLoggedIn={isLoggedIn} user={user} />
      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <Sidebar onLogout={handleLogout} isLoggedIn={isLoggedIn} user={user} practices={practices} />
        {!isLoggedIn && <LoginPage onLoginSuccess={handleLoginSuccess} />}

        {isLoggedIn && user?.type == 'Admin' && (
          <>
            <AdminContent fetchUser={fetchUser} />
          </>
        )}
        {isLoggedIn && user?.type == 'Coach' && (
          <>
            <CoachContent practices={practices} fetchUser={fetchUser} />
          </>
        )}
        {isLoggedIn && user?.type == 'Klant' && (
          <>
            <UserContent practices={practices} fetchUser={fetchUser} />
          </>
        )}
      </div>
    </div>
  );
};

interface AdminContentProps {
  fetchUser: () => Promise<void>;
}

function AdminContent({fetchUser}: AdminContentProps) {
  return (
    <Routes>
      <Route path="/" element={<HomePage user={null} />} />
      <Route path="/change-password" element={<ChangePasswordPage refresh={fetchUser} />} />
      <Route path="/user-password-change" element={<ChangePasswordPage refresh={fetchUser} />} />
      <Route path="/admin/customers" element={<CustomersPage />} />
      <Route path="/admin/add-customer" element={<AddCustomerPage />} />
      <Route path="/admin/edit-customer/:userId" element={<EditCustomersPage />} />
      <Route path="/admin/customer-details/:userId" element={<CustomerDetailPage />} />
      <Route path="/admin/dashboards" element={<DashboardsPage />} />
      <Route path="/admin/add-dashboard" element={<AddDashboardPage />} />
      <Route path="/admin/edit-dashboard/:dashboardId" element={<EditDashboardPage />} />
      <Route path="/admin/practices" element={<PracticesPage />} />
      <Route path="/admin/add-practice" element={<AddPracticePage />} />
      <Route path="/admin/edit-practice/:practiceId" element={<EditPracticePage />} />
      <Route path="/admin/practice-details/:practiceId" element={<PracticeDetailPage />} />
      <Route path="/dashboard/:dashboardId" element={<DashboardPage />} />
      <Route path="/practice/add-report" element={<AddReportPage />} />
      <Route path="/practice/edit-report/:reportId" element={<EditReportPage />} />
      <Route path="/pdf/:pdfId" element={<PdfPage />} />
    </Routes>
  );
}

interface CoachContentProps {
  practices: Practice[];
  fetchUser: () => Promise<void>;
}

function CoachContent({ practices, fetchUser }: CoachContentProps) {
  return (
    <Routes>
      <Route path="/" element={<CoachHomePage practices={practices} />} />
      <Route path="/change-password" element={<ChangePasswordPage refresh={fetchUser}  />} />
      <Route path="/user-password-change" element={<ChangePasswordPage refresh={fetchUser} />} />
      <Route path="/dashboard/:dashboardId" element={<DashboardPage />} />
      <Route path="/practice/add-report" element={<AddReportPage />} />
      <Route path="/practice/edit-report/:reportId" element={<EditReportPage />} />
      <Route path="/coach/practice-details/:practiceId" element={<CoachPracticeDetailPage />} />
      <Route path="/pdf/:pdfId" element={<PdfPage />} />
    </Routes>
  )
}

interface UserContentProps {
  practices: Practice[];
  fetchUser: () => Promise<void>;
}

function UserContent({ practices, fetchUser }: UserContentProps) {
  return (
    <Routes>
      <Route path="/" element={practices.length > 1 ? <PracticeListPage practices={practices} /> : <div>Loading...</div>} />
      <Route path="/change-password" element={<ChangePasswordPage refresh={fetchUser} />} />
      <Route path="/user-password-change" element={<ChangePasswordPage refresh={fetchUser} />} />
      <Route path="/practice/:practiceId" element={<DashboardsHomePage />} />
      <Route path="/practice/:practiceId/reports" element={<ReportsPage />} />
      <Route path="/practice/:practiceId/uploadedPDFs" element={<UploadedPDFsPage />} />
      <Route path="/dashboard/:dashboardId" element={<DashboardPage />} />
      <Route path="/pdf/:pdfId" element={<PdfPage />} />
    </Routes>
  );
}

export default App;