import React, { useEffect, useState } from 'react';
import './DashboardsPage.css';
import axios from 'axios';
import { THEME_COLOR } from '../../../colors';
import Dashboard from '../../../types/Dashboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';
import apiClient from '../../../apiClient';

type SortOrder = 'asc' | 'desc' | 'none';

const DashboardsPage: React.FC = () => {
  const [dashboards, setDashboards] = useState<Dashboard[]>([]);
  const [deletedDashboards, setDeletedDashboards] = useState<Dashboard[]>([]);
  const [showDeleted, setShowDeleted] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [sortConfig, setSortConfig] = useState<{ key: keyof Dashboard | null, order: SortOrder }>({ key: null, order: 'none' });
  const [dashboardToDelete, setDashboardToDelete] = useState<Dashboard | null>(null);
  const [dashboardToReinstate, setDashboardToReinstate] = useState<Dashboard | null>(null);

  const handleSort = (key: keyof Dashboard) => {
    let order: SortOrder = 'asc';
    if (sortConfig.key === key && sortConfig.order === 'asc') {
      order = 'desc';
    } else if (sortConfig.key === key && sortConfig.order === 'desc') {
      order = 'none';
    }
    setSortConfig({ key, order });
  };

  useEffect(() => {
    const fetchAllDashboards = async () => {
      try {
        const response = await apiClient.get('/api/dashboards');
        setDashboards(response.data.dashboards);
      } catch (error) {
        console.error('Error fetching dashboards:', error);
      }
    };

    fetchAllDashboards();
  }, []);

  const fetchDeletedDashboards = async () => {
    try {
      const response = await apiClient.get('/api/dashboards/deleted');
      setDeletedDashboards(response.data.dashboards);
    } catch (error) {
      console.error('Error fetching deleted dashboards:', error);
    }
  };

  const toggleShowDeleted = () => {
    if (!showDeleted) {
      fetchDeletedDashboards();
    }
    setShowDeleted(!showDeleted);
  };

  const sortedFilteredDashboards = () => {
    let filteredDashboards = dashboards.filter(dashboard =>
      dashboard.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (sortConfig.key && sortConfig.order !== 'none') {
      filteredDashboards.sort((a, b) => {
        const aValue = a[sortConfig.key!];
        const bValue = b[sortConfig.key!];

        if (aValue == null || bValue == null) {
          return 0;
        }

        if (aValue < bValue) {
          return sortConfig.order === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.order === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    return filteredDashboards;
  };

  const sortedFilteredDeletedDashboards = () => {
    let filteredDashboards = deletedDashboards.filter(dashboard =>
      dashboard.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (sortConfig.key && sortConfig.order !== 'none') {
      filteredDashboards.sort((a, b) => {
        const aValue = a[sortConfig.key!];
        const bValue = b[sortConfig.key!];

        if (aValue == null || bValue == null) {
          return 0;
        }

        if (aValue < bValue) {
          return sortConfig.order === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.order === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    return filteredDashboards;
  };

  const getSortIcon = (key: keyof Dashboard) => {
    if (sortConfig.key === key) {
      if (sortConfig.order === 'asc') {
        return ' ▲';
      } else if (sortConfig.order === 'desc') {
        return ' ▼';
      }
    }
    return '';
  };

  const handleDeleteClick = (dashboard: Dashboard) => {
    setDashboardToDelete(dashboard);
  };

  const confirmDelete = async () => {
    if (dashboardToDelete) {
      try {
        await apiClient.delete(`/api/dashboards/${dashboardToDelete.id}`);
        setDashboards(dashboards.filter(d => d.id !== dashboardToDelete.id));
      } catch (error) {
        console.error('Error deleting dashboard:', error);
      } finally {
        setDashboardToDelete(null);
      }
    }
  };

  const handleReinstateClick = (dashboard: Dashboard) => {
    setDashboardToReinstate(dashboard);
  };

  const confirmReinstate = async () => {
    if (dashboardToReinstate) {
      try {
        await apiClient.post(`/api/dashboards/${dashboardToReinstate.id}/reinstate`);
        setDeletedDashboards(deletedDashboards.filter(d => d.id !== dashboardToReinstate.id));
        setDashboards([...dashboards, dashboardToReinstate]);
      } catch (error) {
        console.error('Error reinstating dashboard:', error);
      } finally {
        setDashboardToReinstate(null);
      }
    }
  };

  return (
    <div className='content'>
      <h2>Dashboards</h2>
      <input
        type="text"
        placeholder="Zoeken..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />
      <table className="dashboard-table">
        <thead>
          <tr>
            <th onClick={() => handleSort('name')} style={{ cursor: 'pointer' }}>Naam{getSortIcon('name')}</th>
            <th onClick={() => handleSort('grip_dashboard')} style={{ cursor: 'pointer' }}>Grip Dashboard{getSortIcon('grip_dashboard')}</th>
            <th onClick={() => handleSort('type_dashboard')} style={{ cursor: 'pointer' }}>Type{getSortIcon('type_dashboard')}</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sortedFilteredDashboards().map((dashboard) => (
            <tr key={dashboard.id}>
              <td>{dashboard.name}</td>
              <td>{dashboard.grip_dashboard}</td>
              <td>{dashboard.type_dashboard}</td>
              <td><a href={`/admin/edit-dashboard/${dashboard.id}`} style={{ textDecoration: "none", color: THEME_COLOR }}>Bewerken</a></td>
              <td><a href={`/dashboard/${dashboard.id}`} style={{ textDecoration: "none", color: THEME_COLOR }}>Bekijk dashboard</a></td>
              <td>
                <FontAwesomeIcon 
                  icon={faTrash} 
                  style={{ color: THEME_COLOR, marginLeft: '10px', cursor: 'pointer' }} 
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteClick(dashboard);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {dashboardToDelete && (
        <div className="confirmation-dialog">
          <p>Wil je dit dashboard verwijderen?</p>
          <button onClick={confirmDelete}>Ja</button>
          <button onClick={() => setDashboardToDelete(null)}>Nee</button>
        </div>
      )}

      <button 
        style={{ marginTop: '20px', marginBottom: '20px', backgroundColor: THEME_COLOR }} 
        onClick={toggleShowDeleted}
      >
        {showDeleted ? 'Verberg verwijderde dashboards' : 'Toon verwijderde dashboards'}
      </button>

      {showDeleted && (
        <table className="dashboard-table">
          <thead>
            <tr>
              <th onClick={() => handleSort('name')} style={{ cursor: 'pointer' }}>Naam{getSortIcon('name')}</th>
              <th onClick={() => handleSort('grip_dashboard')} style={{ cursor: 'pointer' }}>Grip Dashboard{getSortIcon('grip_dashboard')}</th>
              <th onClick={() => handleSort('type_dashboard')} style={{ cursor: 'pointer' }}>Type{getSortIcon('type_dashboard')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {sortedFilteredDeletedDashboards().map((dashboard) => (
              <tr key={dashboard.id}>
                <td>{dashboard.name}</td>
                <td>{dashboard.grip_dashboard}</td>
                <td>{dashboard.type_dashboard}</td>
                <td>
                  <FontAwesomeIcon 
                    icon={faUndo} 
                    style={{ color: THEME_COLOR, marginLeft: '10px', cursor: 'pointer' }} 
                    onClick={(e) => {
                      e.stopPropagation();
                      handleReinstateClick(dashboard);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {dashboardToReinstate && (
        <div className="confirmation-dialog">
          <p>Wil je dit dashboard weer actief stellen?</p>
          <button onClick={confirmReinstate}>Ja</button>
          <button onClick={() => setDashboardToReinstate(null)}>Nee</button>
        </div>
      )}
    </div>
  );
};

export default DashboardsPage;
