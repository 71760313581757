import React, { useEffect, useState } from 'react';
import './Header.css';
import gripImage from '../../images/logo-grip.png';
import User from '../../types/User';
import { THEME_COLOR } from '../../colors';

interface HeaderProps {
  onLogout: () => void;
  isLoggedIn: boolean;
  user: User | null;
}

const Header: React.FC<HeaderProps> = ({ onLogout, isLoggedIn, user }) => {
  return (
    <div className="header" style={{backgroundColor: THEME_COLOR}}>
      <a href="/">
        <img style={{padding: '10px'}} src={gripImage} alt="GripLogo"></img>
      </a>
      {isLoggedIn &&
      <div className='right'>
        <div className='user-name'>{user?.name}</div>
        {/* <div className='splitser'>|</div>
        <div className="logout-button" style={{backgroundColor: THEME_COLOR}} onClick={onLogout}>Uitloggen</div> */}
      </div>
      }
    </div>
  );
};

export default Header;