import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';
import { THEME_COLOR } from '../../colors';
import Practice from '../../types/Practice';
import Dashboard from '../../types/Dashboard';
import User from '../../types/User';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDog, faCat, faHorse, faCow, faDragon} from '@fortawesome/free-solid-svg-icons';
import apiClient from '../../apiClient';

interface HomePageProps {
  user: User | null;
}

const HomePage: React.FC<HomePageProps> = ({ user }) => {
  const [practices, setPractices] = useState<Practice[]>([]);
  const [selectedPractice, setSelectedPractice] = useState<Practice | null>(null);
  const [dashboards, setDashboards] = useState<Dashboard[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserPractices = async () => {
      if (user) {
        try {
          const response = await apiClient.get(`/api/users/${user.id}`);
          setPractices(response.data.user.practices);
          if (response.data.user.practices.length === 1) {
            setSelectedPractice(response.data.user.practices[0]);
          }
        } catch (error) {
          console.error('Error fetching practices:', error);
        }
      }
    };

    fetchUserPractices();
  }, [user]);

  useEffect(() => {
    const fetchPracticeDashboards = async () => {
      if (selectedPractice) {
        try {
          const response = await apiClient.get(`/api/practices/${selectedPractice.id}`);
          setDashboards(response.data.practice.dashboards);
        } catch (error) {
          console.error('Error fetching dashboards:', error);
        }
      }
    };

    fetchPracticeDashboards();
  }, [selectedPractice]);

  const handlePracticeClick = (practice: Practice) => {
    setSelectedPractice(practice);
  };

  const handleDashboardClick = (dashboardId: number) => {
    navigate(`/dashboard/${dashboardId}`);
  };

  const renderIcon = (type: string) => {
    switch (type) {
      case 'GD':
        return (
          <>
            <FontAwesomeIcon icon={faDog} /> <FontAwesomeIcon icon={faCat} />
          </>
        );
      case 'Paard':
        return <FontAwesomeIcon icon={faHorse} />;
      case 'Rund':
        return <FontAwesomeIcon icon={faCow} />;
      case 'LBH':
        return (
          <>
            <FontAwesomeIcon icon={faDragon} />
          </>
        );
      default:
        return null;
    }
  };

  if (!user) {
    return <div className='content'>Admin!</div>;
  }

  if (selectedPractice) {
    return (
      <div className='content'>
        <h2>Dashboards for {selectedPractice.name}</h2>
        <div className='dashboard-list'>
          {dashboards.map(dashboard => (
            <button
              key={dashboard.id}
              className='dashboard-button'
              style={{ backgroundColor: THEME_COLOR }}
              onClick={() => handleDashboardClick(dashboard.id)}
            >
              {renderIcon(dashboard.type_dashboard)} {dashboard.type_dashboard}
            </button>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className='content'>
      <h2>Grip Home</h2>
      {practices.length > 1 ? (
        <div className='practice-list'>
          {practices.map(practice => (
            <button
              key={practice.id}
              className='practice-button'
              style={{ backgroundColor: THEME_COLOR }}
              onClick={() => handlePracticeClick(practice)}
            >
              {practice.name}
            </button>
          ))}
        </div>
      ) : (
        practices.length === 1 && (
          <div>
            <h2>Dashboards for {practices[0].name}</h2>
            <div className='dashboard-list'>
              {dashboards.map(dashboard => (
                <button
                  key={dashboard.id}
                  className='dashboard-button'
                  style={{ backgroundColor: THEME_COLOR }}
                  onClick={() => handleDashboardClick(dashboard.id)}
                >
                  {renderIcon(dashboard.type_dashboard)} {dashboard.type_dashboard}
                </button>
              ))}
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default HomePage;
