import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import './UploadedPDFsPage.css'; // Optional: Add styling specific to this page
import Pdf from '../../types/Pdf';
import apiClient from '../../apiClient';

const UploadedPDFsPage: React.FC = () => {
  const { practiceId } = useParams<{ practiceId: string }>();
  const [uploadedPdfs, setUploadedPdfs] = useState<Pdf[]>([]);
  const navigate = useNavigate();

  const fetchUploadedPdfs = async () => {
    if (practiceId) {
      try {
        const response = await apiClient.get(`/api/practices/${practiceId}/uploaded-pdfs`);
        setUploadedPdfs(response.data);
      } catch (error) {
        console.error('Error fetching PDFs:', error);
      }
    }
  };

  useEffect(() => {
    fetchUploadedPdfs();
  }, [practiceId]);

  return (
    <div className="content">
      <h2>Bestanden</h2>
      <p>Hier kunt u de geuploade bestanden bekijken en downloaden.</p> {/* Descriptive text */}
      <div className="pdf-list">
        {uploadedPdfs.map(pdf => (
          <div key={pdf.id} className="pdf-item">
            <a className="download-pdf" onClick={() => navigate(`/pdf/${pdf.id}`)}>
              {pdf.name}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UploadedPDFsPage;