// src/pages/PracticeListPage/PracticeListPage.tsx

import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PracticeListPage.css';
import { THEME_COLOR } from '../../colors';
import Practice from '../../types/Practice';

interface PracticeListPageProps {
  practices: Practice[];
}

const PracticeListPage: React.FC<PracticeListPageProps> = ({ practices }) => {
  const navigate = useNavigate();

  const handlePracticeClick = (practice: Practice) => {
    navigate(`/practice/${practice.id}`);
  };

  return (
    <div className="content">
      <h2>Uw Praktijken</h2>
      <div className="practice-list">
        {practices.map(practice => (
          <button
            key={practice.id}
            className="practice-button"
            style={{ backgroundColor: THEME_COLOR }}
            onClick={() => handlePracticeClick(practice)}
          >
            {practice.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default PracticeListPage;
