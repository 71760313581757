import axios from "axios";

const apiClient = axios.create({
  baseURL: "https://grip-klantportaal-backend-dhhhghd8eraeg0e7.westeurope-01.azurewebsites.net",
  headers: {
    "Content-Type": "application/json",
  },
});

// const apiClient = axios.create({
//     headers: {
//       "Content-Type": "application/json",
//     },
//   });

export default apiClient;
